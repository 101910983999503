<template>
<div>
  <md-card class="md-elevation-1">
    <md-card-header>
      <div class="md-title">账号信息</div>
    </md-card-header>
    <md-card-content>
      <el-form  ref="form"
                :model="form"
                :rules="rules"
                inline="false"
                label-width="120px">
        <el-form-item label="账号类型" prop="type">
          <el-radio-group v-model="form.type">
            <el-radio label="智慧场馆"></el-radio>
            <el-radio label="微信推送"></el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="姓名" prop="name">
          <el-input v-model="form.name" placeholder="请输入姓名"></el-input>
        </el-form-item>
        <div v-if="form.type==='智慧场馆'">
          <el-form-item label="学号" prop="student_id">
            <el-input v-model="form.student_id" placeholder="请输入学号"></el-input>
          </el-form-item>
          <el-form-item label="密码" prop="password">
            <el-input v-model="form.password" placeholder="请输入密码"></el-input>
          </el-form-item>
          <el-form-item label="手机号" prop="phone">
            <el-input v-model="form.phone" placeholder="智慧场馆登记的手机号"></el-input>
          </el-form-item>
          <el-form-item label="余额密码">
            <el-input v-model="form.balance_pass" placeholder="智慧场馆余额密码"></el-input>
          </el-form-item>
        </div>
        <div v-if="form.type==='微信推送'">
          <el-form-item label="SendKey" prop="send_key">
            <el-input v-model="form.send_key" placeholder="请输入Server酱SendKey"></el-input>
          </el-form-item>
        </div>
      </el-form>
    </md-card-content>

    <md-card-actions>
      <md-button @click="goBack">取消</md-button>
      <md-button class="md-raised md-primary" @click="submitForm('form')">立即添加</md-button>
    </md-card-actions>
  </md-card>
</div>
</template>

<script>
import axios from 'axios'
import cryptojs from 'crypto-js'

export default {
  name: 'AddAccount',
  data () {
    return {
      form: {
        type: '智慧场馆',
        name: '',
        student_id: '',
        password: '',
        phone: '',
        send_key: '',
        balance_pass: ''
      },
      rules: {
        type: [
          { required: true, message: '请选择账号类型', trigger: 'change' }
        ],
        name: [
          { required: true, message: '请输入姓名', trigger: 'change' }
        ],
        student_id: [
          { required: true, message: '请输入学号', trigger: 'change' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'change' }
        ],
        phone: [
          { required: true, message: '请输入手机号', trigger: 'change' },
          { min: 11, max: 11, message: '请输入11位手机号' }
        ],
        send_key: [
          { required: true, message: '请输入SendKey', trigger: 'change' }
        ]
      }
    }
  },
  methods: {
    goBack () {
      this.$router.push('/account')
    },
    generateSecretKey (keyLength) {
      const keyBytes = new Uint8Array(keyLength)
      crypto.getRandomValues(keyBytes)
      const keyBase64 = btoa(String.fromCharCode(...keyBytes))
      return keyBase64
    },
    aesEncrypt (data, secretKey) {
      // 将数据转换为JSON字符串
      const plaintext = JSON.stringify(data)

      // 将秘钥转换为字节数组
      // eslint-disable-next-line camelcase
      const key_bytes = cryptojs.enc.Utf8.parse(secretKey)

      // 随机生成16字节的向量
      // eslint-disable-next-line camelcase
      const iv_bytes = cryptojs.lib.WordArray.random(16)

      // 使用AES-CBC模式进行加密
      // eslint-disable-next-line camelcase
      const ciphertext_bytes = cryptojs.AES.encrypt(plaintext, key_bytes, {
        iv: iv_bytes,
        mode: cryptojs.mode.CBC,
        padding: cryptojs.pad.Pkcs7
      }).ciphertext

      // 将向量和密文合并，并将合并后的结果转换为Base64编码字符串
      // eslint-disable-next-line camelcase
      const ciphertext_with_iv = iv_bytes.concat(ciphertext_bytes)
      return cryptojs.enc.Base64.stringify(ciphertext_with_iv)
    },
    submitForm (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const formData = {}
          formData.type = this.form.type
          if (formData.type === '智慧场馆') {
            formData.name = this.form.name
            formData.student_id = this.form.student_id
            formData.password = this.form.password
            formData.phone = this.form.phone
            formData.balance_pass = this.form.balance_pass
          } else {
            formData.name = this.form.name
            formData.send_key = this.form.send_key
          }

          const key = this.generateSecretKey(16)
          let url = null
          if (formData.type === '智慧场馆') {
            url = 'add_epe_account'
          } else {
            url = 'add_wechat_account'
          }
          axios.post(url, {
            secret_key: key,
            ciphertext: this.aesEncrypt(formData, key),
            token: localStorage.getItem('token')
          }).then(res => {
            this.loading = false
            if (res.data.code === 200) {
              this.$message({
                message: '添加成功！',
                type: 'success'
              })
              this.$router.push('/account')
            } else {
              this.$message({
                message: '添加失败！' + res.data.message,
                type: 'error'
              })
            }
          }).catch(err => {
            this.loading = false
            console.log(err)
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    }
  }
}
</script>

<style scoped>
.el-form-item {
  display: flex;
  flex-direction: row;
}

::v-deep .el-form-item__content{
  margin-left: 20px;
  /*background: green;*/
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.md-card {
  display: inline-block;
  vertical-align: top;
  text-align: left;
  width: 100%;
}
</style>
