<template>
  <div style="text-align: left">
    <md-dialog-confirm
      :md-active.sync="dialog_active_delete_epe"
      md-title="删除智慧场馆账号?"
      md-confirm-text="删除"
      md-cancel-text="取消"
      @md-cancel="onCancelDeleteEpe"
      @md-confirm="onConfirmDeleteEpe" />

    <md-dialog-confirm
      :md-active.sync="dialog_active_delete_wechat"
      md-title="删除微信推送账号?"
      md-confirm-text="删除"
      md-cancel-text="取消"
      @md-cancel="onCancelDeleteWechat"
      @md-confirm="onConfirmDeleteWechat" />

    <button class="mdui-fab mdui-color-pink-400 mdui-fab-fixed md-elevation-15"
            @click="goToAddPage"
            style="z-index: 100">
      <i class="mdui-icon material-icons">add</i>
    </button>

    <md-card class="md-elevation-1">
      <md-card-header>
        <div class="md-title">智慧场馆账号</div>
      </md-card-header>
      <md-card-content style="padding: 0;">
        <el-empty v-if="tableData.length===0" description="暂无数据" :image-size="80"></el-empty>
        <table v-if="tableData.length!==0" class="epe_table mdui-table md-elevation-0">
          <thead>
          <tr>
            <th>姓名</th>
            <th>学号</th>
            <th>余额</th>
<!--            <th></th>-->
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item,index) in tableData" :key="index">
            <td>{{item.name}}</td>
            <td>{{item.student_id}}</td>
            <td>
              <div style="display: flex;flex-direction: row;align-items: center">
                <span style="width: 60px">{{getDisplayBalance(item.student_id)}}</span>
                <i class="material-icons md-icon refresh_icon"
                   @click="refreshBalance(item.student_id)">refresh</i>
                <i class="material-icons md-icon delete_icon"
                   style="margin-left: 5px"
                   @click="deleteEpeAccount(item.id)">delete</i>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </md-card-content>
    </md-card>

    <md-card style="margin-top: 20px" class="md-elevation-1">
      <md-card-header>
        <div class="md-title">微信推送账号</div>
      </md-card-header>
      <md-card-content style="padding: 0">
        <el-empty v-if="tableData2.length===0" description="暂无数据" :image-size="80"></el-empty>
        <table v-if="tableData2.length!==0" class="mdui-table md-elevation-0 epe_table">
          <thead>
          <tr>
            <th>姓名</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item,index) in tableData2" :key="index">
            <td>{{item.name}}</td>
            <td><i class="material-icons md-icon delete_icon"
                   @click="deleteWechatAccount(item.id)">delete</i></td>
          </tr>
          </tbody>
        </table>
      </md-card-content>
    </md-card>

    <div style="height: 54px"></div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'AccountView',
  data () {
    return {
      tableData: [], // 智慧场馆账号
      tableData2: [], // 微信推送账号
      dialog_active_delete_epe: false,
      dialog_active_delete_wechat: false,
      deleted_epe_account_id: null,
      deleted_wechat_account_id: null,
      balance: {} // 余额，学号:float金额
    }
  },
  methods: {
    getEpeAccountsInfo () {
      // 获取智慧场馆账号信息
      axios.post('get_all_epe_accounts', { token: localStorage.getItem('token') }).then(res => {
        this.tableData = res.data.data

        // 生成学号list获取余额信息存到balance变量
        const accounts = [];
        for(let i=0; i<this.tableData.length; i++){
          accounts.push(this.tableData[i].student_id)
        }
        this.getBalanceInfo(accounts)

      }).catch(err => {
        if (err.response.status === 401) {
          // 身份认证失败，有可能是7天免登录过期了
          localStorage.removeItem('token')
          this.$router.push('/')
          this.$message({
            message: '身份认证过期（失败）！',
            type: 'warning'
          })
        }
      })
    },
    getWechatAccountsInfo () {
      // 获取微信推送账号信息
      axios.post('get_all_wechat_accounts', { token: localStorage.getItem('token') }).then(res => {
        this.tableData2 = res.data.data
      }).catch(err => {
        if (err.response.status === 401) {
          // 身份认证失败，有可能是7天免登录过期了
          localStorage.removeItem('token')
          this.$router.push('/')
          this.$message({
            message: '身份认证过期（失败）！',
            type: 'warning'
          })
        }
      })
    },
    getBalanceInfo(accounts){
      // 获取账号余额信息
      axios.post('get_all_balance', { token: localStorage.getItem('token'), accounts: accounts }).then(res => {
        if(res.data.code===200){
          this.balance = res.data.data
        }else{
          this.$message({
            message: '更新余额失败！' + res.data.message,
            type: 'warning'
          })
        }
      }).catch(err => {
        this.$message({
          message: err,
          type: 'error'
        })
      })
    },
    onConfirmDeleteEpe () {
      axios.post('delete_epe_account', { account_id: this.deleted_epe_account_id, token: localStorage.getItem('token') }).then(res => {
        if (res.data.code === 200) {
          this.$message({
            message: '删除成功！',
            type: 'success'
          })
          this.getEpeAccountsInfo()
        } else {
          this.$message({
            message: '删除失败！' + res.data.message,
            type: 'error'
          })
        }
      }).catch(err => {
        console.log(err)
      })
    },
    onConfirmDeleteWechat () {
      axios.post('delete_wechat_account', { account_id: this.deleted_wechat_account_id, token: localStorage.getItem('token') }).then(res => {
        if (res.data.code === 200) {
          this.$message({
            message: '删除成功！',
            type: 'success'
          })
          this.getWechatAccountsInfo()
        } else {
          this.$message({
            message: '删除失败！' + res.data.message,
            type: 'error'
          })
        }
      }).catch(err => {
        console.log(err)
      })
    },
    onCancelDeleteEpe () {
      // this.dialog_active = false
    },
    onCancelDeleteWechat () {
      // this.dialog_active = false
    },
    // eslint-disable-next-line camelcase
    deleteEpeAccount (account_id) {
      // eslint-disable-next-line camelcase
      this.deleted_epe_account_id = account_id
      this.dialog_active_delete_epe = true
    },
    // eslint-disable-next-line camelcase
    deleteWechatAccount (account_id) {
      // eslint-disable-next-line camelcase
      this.deleted_wechat_account_id = account_id
      this.dialog_active_delete_wechat = true
    },
    goToAddPage () {
      this.$router.push({
        name: 'add_account',
        params: {
          type: 'test'
        }
      })
    },
    getDisplayBalance (student_id) {
      if(!(student_id in this.balance)){
        return "/"
      }
      else if(this.balance[student_id]===null){
        return "/"
      }else{
        return "￥"+this.balance[student_id]["balance"]
      }
    },
    refreshBalance(student_id){
      axios.post('update_user_balance', { account_id: student_id, token: localStorage.getItem('token') }).then(res => {
        if (res.data.code === 200) {
          this.$message({
            message: '更新余额成功！',
            type: 'success'
          })
          this.balance[student_id]={'balance':res.data.balance}
        } else {
          this.$message({
            message: '更新余额失败！' + res.data.message,
            type: 'error'
          })
        }
      }).catch(err => {
        console.log(err)
      })
    }
  },
  mounted () {
    this.getEpeAccountsInfo()
    this.getWechatAccountsInfo()
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.md-icon {
  width: 20px;
  min-width: 20px;
  height: 20px;
  font-size: 20px !important;
  margin: 0;
}

.epe_table{
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-bottom: none !important;
}

.refresh_icon{
  color: #757575;
  width: 20px;
  height: 20px;
  cursor: pointer;
  //margin-right: 5px;
}
.delete_icon{
  color: #757575;
  width: 20px;
  height: 20px;
  cursor: pointer
}
</style>
