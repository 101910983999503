<template>
  <div>
    <el-card class="box-card">
      <h2>登录</h2>
      <el-form
        :model="form"
        status-icon
        :rules="rules"
        ref="form"
        label-position="left"
        label-width="70px"
        class="login-from"
      >
        <el-form-item label="用户名" prop="username">
          <el-input v-model="form.username" clearable></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input
            type="password"
            v-model="form.password"
            autocomplete="off"
            clearable
            @keyup.enter.native="submitForm('form')"
          ></el-input>
        </el-form-item>
      </el-form>
      <div class="btnGroup">
        <md-button class="md-dense md-raised md-primary"
                   @click="submitForm('form')"
                   :loading="loading">登录</md-button>
      </div>
    </el-card>
  </div>
</template>

<script>
import axios from 'axios'
import cryptojs from 'crypto-js'

export default {
  name: 'LoginView',
  components: {
  },
  data () {
    return {
      loading: false,
      form: {
        username: '',
        password: ''
      },
      rules: {
        username: [
          { required: true, message: '用户名不能为空！', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '密码不能为空！', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    submitForm (formName) {
      this.$refs[formName].validate((valid) => {
        this.loading = true
        const key = this.generateSecretKey(16)
        if (valid) {
          // alert('submit!')
          // this.$http.post('login',
          axios.post('login',
            {
              secret_key: key,
              ciphertext: this.aesEncrypt(this.form, key)
            }).then(res => {
            if (res.status === 200) {
              if (res.data.code === 200) {
                // 将用户信息存储到localStorage中
                localStorage.setItem('token', res.data.token)
                // 跳转页面到首页
                this.$router.push('/task')
                // 显示后端响应的成功信息
                this.$message({
                  message: '登录成功！',
                  type: 'success'
                })
              } else {
                this.$message({
                  message: res.data.message,
                  type: 'warning'
                })
              }
            }
            this.loading = false
          }).catch(err => {
            this.$message({
              message: err,
              type: 'warning'
            })
            this.loading = false
          })
        } else {
          this.loading = false
          return false
        }
      })
    },
    generateSecretKey (keyLength) {
      const keyBytes = new Uint8Array(keyLength)
      crypto.getRandomValues(keyBytes)
      const keyBase64 = btoa(String.fromCharCode(...keyBytes))
      return keyBase64
    },
    aesEncrypt (data, secretKey) {
      // 将数据转换为JSON字符串
      const plaintext = JSON.stringify(data)

      // 将秘钥转换为字节数组
      // eslint-disable-next-line camelcase
      const key_bytes = cryptojs.enc.Utf8.parse(secretKey)

      // 随机生成16字节的向量
      // eslint-disable-next-line camelcase
      const iv_bytes = cryptojs.lib.WordArray.random(16)

      // 使用AES-CBC模式进行加密
      // eslint-disable-next-line camelcase
      const ciphertext_bytes = cryptojs.AES.encrypt(plaintext, key_bytes, {
        iv: iv_bytes,
        mode: cryptojs.mode.CBC,
        padding: cryptojs.pad.Pkcs7
      }).ciphertext

      // 将向量和密文合并，并将合并后的结果转换为Base64编码字符串
      // eslint-disable-next-line camelcase
      const ciphertext_with_iv = iv_bytes.concat(ciphertext_bytes)
      return cryptojs.enc.Base64.stringify(ciphertext_with_iv)
    }
  }
}
</script>

<style scoped>
.box-card {
  max-width: 400px;
  margin: 80px auto auto;
}
.login-from {
  margin: auto auto;
}
</style>
