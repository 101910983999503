<template>
  <div>
    <md-card class="md-elevation-1">
      <md-card-header style="padding-bottom: 0">
        <div class="md-title">场号选择</div>
      </md-card-header>
      <div class="mdui-card-content">
        新增任务时，用户可以指定多个目标场号，系统按照指定的顺序依次进行抢占；如果用户没有指定额外的场号顺序，就会随机进行抢占。
      </div>
      <md-card-header style="padding-bottom: 0">
        <div class="md-title">定时任务时间说明</div>
      </md-card-header>
      <div class="mdui-card-content">
        系统每天<el-tag size="small">11:56</el-tag>读取日期为+3天后的抢占任务，<el-tag size="small">11:57</el-tag>登录任务涉及到的智慧场馆账号，<el-tag size="small">11:58:30</el-tag>多线程为每个抢占任务分配12:00:00的定时任务，<el-tag size="small">12:00</el-tag>并发执行抢占任务，<el-tag size="small">12:02</el-tag>执行后处理工作，包括更新未运行的任务，执行微信推送等，<el-tag size="small">12:03</el-tag>更新长期场信息。
      </div>
      <md-card-header style="padding-bottom: 0">
        <div class="md-title">防封号</div>
      </md-card-header>
      <div class="mdui-card-content">
        对于一个抢占任务，如果失败，会重复执行，最多一共执行<el-tag size="small">7</el-tag>次。每一次执行最多发送<el-tag size="small">5</el-tag>个请求，每次执行最短耗时约<el-tag size="small">2.2s</el-tag>左右。如果多个订单使用了同一个智慧场馆账号，只会随机选择<el-tag size="small">1</el-tag>个订单执行。
      </div>
      <md-card-actions style="display: flex;justify-content: center">
        <md-button class="md-dense md-raised mdui-color-red-800"  @click="logOut">退出登陆</md-button>
      </md-card-actions>
    </md-card>
  </div>
</template>

<script>
export default {
  name: 'ConfigView',
  data () {
    return {
    }
  },
  methods: {
    logOut () {
      localStorage.clear()
      this.$message({
        message: '退出成功！',
        type: 'success'
      })
      this.$router.push('/')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.md-button {
  min-width: 60px;
  border-radius: 2px;
}

.md-card {
  display: inline-block;
  vertical-align: top;
  text-align: left;
  width: 100%;
}

.el-tag{
  margin-right: 5px;
  margin-left: 5px;
}
</style>
