import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui'
import './plugins/element.js'
import 'element-ui/lib/theme-chalk/index.css'
import axios from 'axios'
import VueMaterial from 'vue-material'
import 'vue-material/dist/vue-material.min.css'
import 'vue-material/dist/theme/default.css'
import VueDeviceDetector from 'vue-device-detector'

Vue.config.productionTip = false
Vue.use(ElementUI)
Vue.use(VueMaterial)
Vue.use(VueDeviceDetector)

// axios.defaults.baseURL = 'https://127.0.0.1:8999'
// axios.defaults.baseURL = 'https://114.115.141.239:8999'
axios.defaults.baseURL = 'https://liufengx.space:8999'
Vue.prototype.$http = axios

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
