<template>
<div>
  <md-card class="md-elevation-1">
    <md-card-header style="padding-bottom: 0">
      <div class="md-title">系统日志</div>
    </md-card-header>
    <div class="mdui-card-content">
      <ul class="mdui-list">
        <el-empty v-if="logs.length===0" description="暂无数据" :image-size="80"></el-empty>
        <li v-for="(log,index) in logs" :key="index">{{log}}</li>
      </ul>
    </div>
  </md-card>
</div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'LogView',
  data () {
    return {
      logs: []
    }
  },
  methods: {
    getLogs () {
      axios.post('get_logs', { token: localStorage.getItem('token') }).then(res => {
        if(res.data.code===200){
          // 接口返回最多300条数据，只显示最近的200条
          this.logs = res.data.data.slice(-200)
          this.logs.reverse()
        }else{
          this.$message({
            message: res.data.message,
            type: 'warning'
          })
        }
      }).catch(err => {
        if (err.response.status === 401) {
          // 身份认证失败，有可能是7天免登录过期了
          localStorage.removeItem('token')
          this.$router.push('/')
          this.$message({
            message: '身份认证过期（失败）！',
            type: 'warning'
          })
        }
      })
    }
  },
  mounted () {
    this.getLogs()
  }
}
</script>

<style scoped>
.md-card {
  display: inline-block;
  vertical-align: top;
  text-align: left;
  width: 100%;
}
</style>
