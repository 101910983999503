import Vue from 'vue'
import VueRouter from 'vue-router'
import LoginView from '../views/LoginView.vue'
import AddTask from '@/views/AddTaskView'
import AddAccount from '@/views/AddAccountView'
import TaskView from '@/views/TaskView'
import AccountView from '@/views/AccountView'
import ConfigView from '@/views/ConfigView'
import { Message } from 'element-ui'
import LogView from '@/views/LogView'
import HistoryView from "@/views/HistoryView";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: LoginView
  },
  {
    path: '/task',
    name: 'task',
    component: TaskView
  },
  {
    path: '/account',
    name: 'account',
    component: AccountView
  },
  {
    path: '/config',
    name: 'config',
    component: ConfigView
  },
  {
    path: '/task/add_task',
    name: 'add_task',
    component: AddTask
  },
  {
    path: '/account/add_account',
    name: 'add_account',
    component: AddAccount
  },
  {
    path: '/log',
    name: 'log',
    component: LogView
  },
  {
    path: '/history',
    name: 'history',
    component: HistoryView
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    //x代表横坐标，y代表纵坐标
    return { x: 0,y: 0 };
  }
})

// 导航守卫，前置处理
router.beforeEach((to, from, next) => {
  const isAuthenticated = !!localStorage.getItem('token')
  // 如果路由要跳转到除了登录和注册的界面的话就判断是否已经登录，如果没有登录就强制跳到登录界面
  if (to.path !== '/' && !isAuthenticated) {
    Message({
      message: '请先登录！',
      type: 'warning'
    })
    next({ path: '/' })
  } else next()
})

export default router
