<template>
  <div>
    <div class="chip_container">
      <div class="mdui-chip chip_style"
           :class="{chip_selected: display_qdb}"
           @click="displayQDB">
<!--        <div v-show="display_qdb" class="check_icon"><i class="mdui-icon material-icons" style="color: black;">check</i></div>-->
        <span class="mdui-chip-title">邱德拔</span>
      </div>

      <div class="mdui-chip chip_style"
           :class="{chip_selected: !display_qdb}"
           @click="display54">
<!--        <div v-show="!display_qdb" class="check_icon"><i class="mdui-icon material-icons" style="color: black;">check</i></div>-->
        <div class="mdui-chip-title">五四</div>
      </div>
    </div>

    <div class="container" v-for="(value,key) in history_data" :key="key">
      <h2 class="md-title" style="display: flex;justify-content: space-between;">
        <span style="display: flex;align-items: center; color: rgba(0,0,0,0.87);font-weight: 400">{{ key }} {{getDayOfWeek(key)}}</span>
      </h2>
      <table class="table" v-if="display_qdb">
        <tr v-for="row in 16" :key="row">
          <td v-for="col in 13"
              :key="col"
              :class="getCellStyle(row,col,value)">{{getCellContent(row,col)}}</td>
        </tr>
      </table>
      <table class="table" v-else>
        <tr v-for="row in 17" :key="row">
          <td v-for="col in 10"
              :key="col"
              :class="getCellStyle(row,col,value)">{{getCellContent(row,col)}}</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "HistoryView",
  data () {
    return {
      display_qdb: true,
      history_data: {}
    }
  },
  methods:{
    displayQDB(){
      if(!this.display_qdb){
        this.display_qdb=true
        this.getHistoryData("qdb")
      }
    },
    display54(){
      if(this.display_qdb){
        this.display_qdb=false
        this.getHistoryData("54")
      }
    },
    getCellContent(row,col){
      if(row===1 && col>1){
        return col-1
      }else if(col===1 && row>1){
        if(this.display_qdb){
          return row>3?8+row-2:"0"+(8+row-2)
        }else{
          return row>4?7+row-2:"0"+(7+row-2)
        }
      }
      else{
        return ""
      }
    },
    getCellStyle(row,col,his_data) {
      if (row === 1 || col === 1) {
        return ""
      } else if (his_data[row - 2][col - 2] === 1) {
        return "cell available"
      } else if (his_data[row - 2][col - 2] === -1) {
        return "cell unavailable"
      } else if (his_data[row - 2][col - 2] === 0) {
        return "cell taken"
      } else {
        return "cell"
      }
    },
    getHistoryData(venue_type){
      this.history_data={}
      axios.post('get_history', { token: localStorage.getItem('token') ,venue_type: venue_type}).then(res => {
        if(res.data.code===200){
          this.history_data = res.data.data
        }else{
          this.$message({
            message: res.data.message,
            type: 'warning'
          })
        }
      }).catch(err => {
        console.log(err)
      })
    },
    getDayOfWeek (dateString) {
      const daysOfWeek = ['周日', '周一', '周二', '周三', '周四', '周五', '周六']
      const date = new Date(dateString)
      const dayOfWeekIndex = date.getDay()
      return daysOfWeek[dayOfWeekIndex]
    }
  },
  mounted() {
    this.getHistoryData("qdb")
  }
}
</script>

<style scoped>

.table {
  border-collapse: collapse;
}

.table td {
  /*border: 1px solid white;*/
  padding: 0;
}

.cell {
  border: 1px solid white;
  width: 40px;
  height: 30px;
}

.available{
  background-color: #8ad470;
}

.unavailable{
  background-color: lightgray;
}

.taken{
  background-color: #fdc237;
}

.chip_style{
  display: flex;
  flex-direction: row;
}

.chip_container {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 16px;
  margin-top: 0;
}

.mdui-chip{
  margin-right: 10px;
}

.chip_selected{
  background: rgba(0,0,0,0.24);
}

.check_icon{
  background: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left:4px;
}

.container{
  /*background: red;*/
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 32px;
}
</style>
